import { useEffect, useState } from "react";
import __foreach from 'lodash/forEach';
import styles from './redlnotrenderableinfo.module.scss';

export default function RedlNotRenderableInfo(props) {
    const { pageNotRenderableInfo } = props;
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (pageNotRenderableInfo) {
            for (const [dataId, itemsArray] of Object.entries(pageNotRenderableInfo)) {
                let minIndex = 0, maxIndex = 0;
                const componentNames = [];
                __foreach(itemsArray, (componentName, index) => {
                    if (componentName) {
                        if (!minIndex) {
                            minIndex = maxIndex = index;
                        } else {
                            maxIndex = index;
                        }
                        if (componentNames.indexOf(componentName) === -1) {
                            componentNames.push(componentName);
                        }
                    }

                });
                const itemsMessage = minIndex === maxIndex
                    ? `l'ítem ${minIndex} necessari`
                    : `els ítems ${minIndex}-${maxIndex} necessaris`;
                const modulesMessage = componentNames.length === 1
                    ? `el mòdul "${componentNames[0]}"`
                    : `els mòduls "${componentNames.join('", "')}"`;
                const dataIdMessage = `La font de dades "${dataId}"`
                    + ` actualment no conté ${itemsMessage}`
                    + ` per a omplir ${modulesMessage}`
                    + ` del final de la pàgina actual.`;
                setMessage((prevState) => {
                    return {
                        ...prevState,
                        [dataId]: dataIdMessage
                    };
                });
            }
        }
    }, [pageNotRenderableInfo]);

    const renderMessage = () => {
        return Object.entries(message).map(([dataId, dataIdMessage]) => {
            return (
                <p key={dataId} className={styles.redlNotRenderableInfo__text}>{dataIdMessage}</p>
            );
        });
    };

    return (
        <div className={styles.redlNotRenderableInfo}>
            {renderMessage()}
        </div>
    );
};