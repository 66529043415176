import { dataResources } from '@portal-internet/core';

const BASE_PATH = '';
dataResources.basePath = BASE_PATH;

const WEBPROGRAMA_HOME_PREFIX_LAYOUT_CUSTOM = '_custom';
dataResources['homeWPCustom'] = WEBPROGRAMA_HOME_PREFIX_LAYOUT_CUSTOM;
const WEBPROGRAMA_HOME_PREFIX_TEMPLATE = '_homewp_';
dataResources['homeWPTemplate'] = WEBPROGRAMA_HOME_PREFIX_TEMPLATE;

dataResources.logosPath = 'https://statics.3cat.cat/recursos/3cat/logos/web/';

export { dataResources };
