import * as BlocDeContingutsDataOptimizer from '@portal-internet/core/components/3cat/web/BlocDeContinguts/optimize.specs';
import * as CercadorDataOptimizer from '@portal-internet/core/components/3cat/web/Cercador/optimize.specs';
import dynamic from 'next/dynamic';

const trescatComponents = {
    AtencioAudiencia: { dynamicComponent: dynamic(() => import(`components/3cat/AtencioAudiencia`)) },
    Audiencies: { dynamicComponent: dynamic(() => import(`components/3cat/Audiencies`)) },
    Az: { dynamicComponent: dynamic(() => import(`components/3cat/Az`)) },
    BlocDeContinguts: {
        dynamicComponent: dynamic(() => import(`components/3cat/BlocDeContinguts`)),
        dataOptimizer: BlocDeContingutsDataOptimizer
    },
    BlocDeContingutsWrapper: { dynamicComponent: dynamic(() => import(`components/3cat/BlocDeContingutsWrapper`)) },
    Capcalera: { dynamicComponent: dynamic(() => import(`components/3cat/Capcalera`)) },
    Cercador: {
        dynamicComponent: dynamic(() => import(`components/3cat/Cercador`)),
        dataOptimizer: CercadorDataOptimizer
    },
    CodiEspectador: { dynamicComponent: dynamic(() => import(`components/3cat/CodiEspectador`)) },
    Columna: { dynamicComponent: dynamic(() => import(`components/3cat/Columna`)) },
    Compartir: { dynamicComponent: dynamic(() => import(`components/3cat/Compartir`)) },
    Destacat: { dynamicComponent: dynamic(() => import(`components/3cat/Destacat`)) },
    DirecteNoDisponible: { dynamicComponent: dynamic(() => import(`components/3cat/DirecteNoDisponible`)) },
    Dropdown: { dynamicComponent: dynamic(() => import(`components/3cat/Dropdown`)) },
    Embed: { dynamicComponent: dynamic(() => import(`components/3cat/Embed`)) },
    Enllac: { dynamicComponent: dynamic(() => import(`components/3cat/Enllac`)) },
    ExclusiusDigitals: { dynamicComponent: dynamic(() => import(`components/3cat/ExclusiusDigitals`)) },
    Fila: { dynamicComponent: dynamic(() => import(`components/3cat/Fila`)) },
    FilterMosaic: { dynamicComponent: dynamic(() => import(`components/3cat/FilterMosaic`)) },
    Grafica: { dynamicComponent: dynamic(() => import(`components/3cat/Grafica`)) },
    ItemNoticia: { dynamicComponent: dynamic(() => import(`components/3cat/ItemNoticia`)) },
    Itemaudio: { dynamicComponent: dynamic(() => import(`components/3cat/Itemaudio`)) },
    Itemvideo: { dynamicComponent: dynamic(() => import(`components/3cat/Itemvideo`)) },
    LogoPrograma: { dynamicComponent: dynamic(() => import(`components/3cat/LogoPrograma`)) },
    LlistatVideos: { dynamicComponent: dynamic(() => import(`components/3cat/LlistatVideos`)) },
    Menu: { dynamicComponent: dynamic(() => import(`components/3cat/Menu`)) },
    Metadades: { dynamicComponent: dynamic(() => import(`components/3cat/Metadades`)) },
    Peu: { dynamicComponent: dynamic(() => import(`components/3cat/Peu`)) },
    Player: { dynamicComponent: dynamic(() => import(`components/3cat/Player`)) },
    Publicitat: { dynamicComponent: dynamic(() => import(`components/3cat/Publicitat`)) },
    MarcatgeEstructurat: { dynamicComponent: dynamic(() => import(`components/3cat/MarcatgeEstructurat`)) },
    MevaLlista: { dynamicComponent: dynamic(() => import(`components/3cat/MevaLlista`)) },
    Modal: { dynamicComponent: dynamic(() => import(`components/Modal`)) },
    Mosaic: { dynamicComponent: dynamic(() => import(`components/3cat/Mosaic`)) },
    NotFound: { dynamicComponent: dynamic(() => import(`components/3cat/NotFound`)) },
    // Onboarding: { dynamicComponent: dynamic(() => import(`components/3cat/Onboarding`)) },
    ProperesEmissions: { dynamicComponent: dynamic(() => import(`components/3cat/ProperesEmissions`)) },
    ServicesListener: { dynamicComponent: dynamic(() => import(`components/ServicesListener`)) },
    Social: { dynamicComponent: dynamic(() => import(`components/3cat/Social`)) },
    Slider: { dynamicComponent: dynamic(() => import(`components/3cat/Slider`)) },
    SliderWrapper: { dynamicComponent: dynamic(() => import(`components/3cat/SliderWrapper`)) },
    Carrussel: { dynamicComponent: dynamic(() => import(`components/3cat/Carrussel`)) },
    Tematics: { dynamicComponent: dynamic(() => import(`components/3cat/Tematics`)) },
    Text: { dynamicComponent: dynamic(() => import(`components/3cat/Text`)) },
    TextItemDestacat: { dynamicComponent: dynamic(() => import(`components/3cat/TextItemDestacat`)) },
    Ultimsdies: { dynamicComponent: dynamic(() => import(`components/3cat/Ultimsdies`)) },
    Tab: { dynamicComponent: dynamic(() => import(`components/3cat/Tab`)) },
    TabList: { dynamicComponent: dynamic(() => import(`components/3cat/TabList`)) },
    TabPanel: { dynamicComponent: dynamic(() => import(`components/3cat/TabPanel`)) },
    TabsCustom: { dynamicComponent: dynamic(() => import(`components/3cat/TabsCustom`)) },
    TornaDalt: { dynamicComponent: dynamic(() => import(`components/3cat/TornaDalt`)) }
};

export { trescatComponents };
