import clsx from 'clsx';
import __isEmpty from 'lodash/isEmpty';
import Link from 'next/link';
import { getUrlFriendly } from './RouteResolver';
import styles from './enllac.module.scss';

const _getUrl = (props) => {
    const { type, route } = props;
    return getUrlFriendly({ ...props, ...route, routePath: route?.type || type, producte: 'web_noticies' });
};

export default function Enllac(props) {
    let { titol, href, estil, className, novaFinestra, route, rel, accesskey, dataTestId, ariaLabel, title } = props;

    const styleLink = clsx({
        [styles[estil]]: !__isEmpty(estil),
        [className]: !__isEmpty(className)
    });

    href = route?.href || href;
    if (__isEmpty(href)) {
        href = _getUrl(props);
    }

    return (
        <Link href={href} scroll={false}>
            <a
                className={styleLink ? styleLink : null}
                target={novaFinestra ? '_blank' : null}
                accessKey={accesskey}
                rel={rel}
                data-testid={dataTestId}
                aria-label={ariaLabel}
                title={title}
            >
                {titol ? titol : props.children}
            </a>
        </Link>
    );
}

Enllac.defaultProps = {
    titol: '',
    href: '',
    estil: '',
    disponible: true,
    cleanUrlParams: false
};
