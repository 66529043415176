import { addParameterToUrl, getDictionaryWebView, getUrlFriendly, setStorageValue } from '@portal-internet/core';
import clsx from 'clsx';
import { useProducte } from 'hooks/3cat/useProducte';
import __isEmpty from 'lodash/isEmpty';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styles from './enllac.module.scss';
import { useGlobalVars } from 'hooks/useGlobalVars';

const _getUrl = (props) => {
    const { type, route } = props;
    const { urlAppPrefix } = useProducte();
    const routePath = route?.type || type;
    const canalMain = route?.canals ? _getCanal(route.canals) : 'sense';
    return getUrlFriendly({ ...props, ...route, routePath: routePath, canal: canalMain, producte: urlAppPrefix });
};

const _getCanal = (canals) => {
    const c = canals.canal ? canals.canal : canals;
    const canalMain = c.find((canal) => canal.main === true);
    return canalMain?.desc || c?.[0]?.desc || 'sense';
};

const _getDiccionariWebViewHref = (route, href, isWebView) => {
    let url =
        isWebView && route
            ? addParameterToUrl(href, 'webview', true) +
              getDictionaryWebView(route.format, route.tipologia, route.tipologiaRelacionada, route.id)
            : href;
    return url;
};
export default function Enllac(props) {
    let {
        titol,
        href,
        estil,
        className,
        novaFinestra,
        route,
        rel,
        accesskey,
        disponible,
        dataTestId,
        ariaLabel,
        cleanUrlParams,
        marcatgeAudienciesClick,
        globalVar
    } = props;
    const { setGlobalVar } = useGlobalVars();
    const { isWebviewActive } = useProducte();
    const [hash, setHash] = useState(null);
    const { asPath } = useRouter();
    useEffect(() => {
        if (isWebviewActive && !cleanUrlParams) {
            let currentHash = asPath.split('#')?.[1];
            if (!__isEmpty(currentHash) && route?.hash) {
                setHash('#' + currentHash + '&' + route.hash);
            } else if (!__isEmpty(currentHash)) {
                setHash('#' + currentHash);
            }
        } else if (route?.hash) {
            setHash('#' + route.hash);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWebviewActive, asPath]);

    const styleLink = clsx({
        [styles[estil]]: !__isEmpty(estil),
        [className]: !__isEmpty(className)
    });

    href = route?.href || href;
    href = __isEmpty(href) ? _getUrl(props) : _getDiccionariWebViewHref(route, href, isWebviewActive);
    if (!__isEmpty(hash)) {
        href = href + hash;
    }

    const setStorageClick = () => {
        const { clickType, clickPosition, clickTitles, clickName } = marcatgeAudienciesClick || {};
        const dataClickOrigin = {
            name: clickName
                ? (clickType ? clickType + ':' : '') + (clickPosition ? clickPosition + ':' : '') + clickName.replace(':', '')
                : '',
            titles: clickTitles
        };
        setStorageValue('ccma_click', dataClickOrigin, { path: '/', domain: '.3cat.cat', expires: 1 });
    };

    const handleOnClick = () => {
        if (!__isEmpty(globalVar)) setGlobalVar(globalVar.varName, globalVar.value);
        setStorageClick();
    };

    return disponible ? (
        isWebviewActive ? (
            <a href={href} className={styleLink} data-testid={dataTestId} aria-label={ariaLabel} onClick={handleOnClick}>
                {titol ? titol : props.children}
            </a>
        ) : (
            <Link href={href}>
                <a
                    className={styleLink ? styleLink : null}
                    target={novaFinestra ? '_blank' : null}
                    accessKey={accesskey}
                    rel={rel}
                    data-testid={dataTestId}
                    aria-label={ariaLabel}
                    onClick={handleOnClick}
                >
                    {titol ? titol : props.children}
                </a>
            </Link>
        )
    ) : (
        <div className={styleLink ? styleLink : null}>{props.children}</div>
    );
}

Enllac.defaultProps = {
    titol: '',
    href: '',
    estil: '',
    disponible: true,
    cleanUrlParams: false
};
